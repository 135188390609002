import { utils, writeFile } from 'xlsx'

export const exportXLSX = (arrayData, fileName) => {
  // 将数据转成workSheet
  const arrayWorkSheet = utils.aoa_to_sheet(arrayData)
  // 构造workBook
  const workBook = {
    SheetNames: ['arrayWorkSheet', 'jsonWorkSheet'],
    Sheets: {
      arrayWorkSheet: arrayWorkSheet
    }
  }
  // 将workBook写入文件
  writeFile(workBook, `${fileName}.xlsx`)
}
